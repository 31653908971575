/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {Body2, H4} from "./type";

export default function Modal({title, subTitle, show, onClose, footer, size, children, }) {
    const s = size ? size : "w-96 h-96 sm:w-120 sm:h-120"
    return (
        <>
            {show &&
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-200 outline-none focus:outline-none"
                     onClick={() => onClose()}>
                    <div className={`relative my-6 mx-auto ${s}`}>
                        <div className="border-0 rounded-full shadow-lg relative flex flex-col w-full h-full bg-cream outline-none focus:outline-none border-4 border-yellow justify-center items-center">
                            {children}
                        </div>
                    </div>
                </div>
                <div className="opacity-75 fixed top-0 left-0 w-full h-full z-100 bg-black"/>
            </>
            }
        </>
    );
}

Modal.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    size: PropTypes.string,
    footer: PropTypes.element,
    children: PropTypes.any,
};
