import React from 'react';
import PropTypes from 'prop-types';

export function H1({className, children}) {
    return (
        <h1 className={`text-6xl md:text-7xl ${className}`}>{children}</h1>
    );
}

H1.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};

export function H2({className, children}) {
    return (
        <h2 className={`text-4xl md:text-6xl ${className}`}>{children}</h2>
    );
}

H2.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};

export function H3({className, children}) {
    return (
        <h3 className={`text-2xl md:text-3xl leading-6 md:leading-8 ${className}`}>{children}</h3>
    );
}

H3.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};


export function H4({className, children}) {
    return (
        <h4 className={`text-lg md:text-2xl leading-6 ${className}`}>{children}</h4>
    );
}

H4.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};

export function H5({className, children}) {
    return (
        <h5 className={`text-md md:text-lg leading-6 ${className}`}>{children}</h5>
    );
}

H5.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};

export function Body1({children, className}) {
    return (
        <P className={`leading-9 text-lg md:text-xl lg:text-2xl ${className}`}>{children}</P>
    )
}
Body1.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

export function Body2({children, className}) {
    return (
        <P className={`text-sm md:text-base ${className}`}>{children}</P>
    )
}
Body2.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

export function P({className, children}) {
    const weight = className.indexOf("font-") === -1 ? "font-thin" : "";
    // const tracking = className.indexof("tracking-") === -1 ? "tracking-wide" : ";"
    return (
        <p className={`text-md tracking-wide ${weight} ${className}`}>{children}</p>
    );
}
P.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};
P.defaultProps = {
    className: ""
}

export function Caption({className, children}) {
    return (
        <p className={`text-sm ${className}`}>{children}</p>
    );
}

Caption.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};

export function B1({className, children}) {
    return (
        <h1 className={`text-8xl md:text-9xl ${className}`}>{children}</h1>
    );
}

B1.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};
