import React from "react";
import PropTypes from "prop-types"
import Modal from "./modal";
import {Body2, H2, H3, H4} from "./type";


export default function BookModal({show, onClose}) {
    return (
        <Modal title="Bookings" show={show} onClose={onClose} >
            <div className="text-center">
                <H2 className="text-brown mb-6">To book</H2>
                <H3 className="text-brown px-8 font-bold">Email or call (no texting).</H3>
                {/*<H4 className="text-brown uppercase underline mt-8">Important:</H4>*/}
                {/*<Body1 className="text-brown px-8 pt-1 mb-8">*/}
                {/*    Check your junk mail for my reply. Make sure to add*/}
                {/*    me in your contacts to avoid missing any emails*/}
                {/*    pertaining to your booked appointment.*/}
                {/*</Body1>*/}
                <H4 className="mt-6 text-brown font-black">
                    <a href="mailto:info@anniebmassage.ca">info@anniebmassage.ca</a>
                </H4>
                <H4 className="text-brown font-black">
                    <a href="tel:403-678-0475">403.678.0475</a>
                </H4>
                <Body2 className="mt-8 text-brown font-medium">
                    <div>
                    Flexible schedule.
                    </div>
                    <div>
                        Available 7 days a week,
                    </div>
                     <div>
                         including evenings,
                     </div>
                    <div>
                        weekends & holidays.
                    </div>
                </Body2>
            </div>
        </Modal>
    )
}

BookModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func
}
