import React from 'react';
import {Animated} from 'react-animated-css';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image';
import Logo from "../images/logo-word.svg";
import {H2} from './type';

const Hero = () => {
    const q = useStaticQuery(graphql`
        query { 
            hero: file(relativePath: {eq: "sun-through-trees.jpg"}) {
                image: childImageSharp {
                    fluid(maxWidth:2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            profile: file(relativePath: {eq: "annie-ali-bike-v2.jpg"}) {
                image: childImageSharp {
                    fluid(maxWidth:728) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }  
         }
    `)
    return (
        <BackgroundImage fluid={q.hero.image.fluid}
                         className="min-h-screen flex flex-col justify-evenly items-center text-white relative">
            <div className="absolute bg-black opacity-50 w-full h-full -z-10"/>
            <div>
              <Logo className="w-96 max-w-full"/>
            </div>
            {/*<div className="text-center">*/}
            {/*    <B1 className="text-beige text-shadow-hero font-black leading-none">annie b.</B1>*/}
            {/*    <H1 className="text-beige tracking-wider font-thin">massage</H1>*/}
            {/*</div>*/}
            <Img fluid={q.profile.image.fluid} className="w-72 rounded-full border-4 border-solid border-beige shadow-2xl"/>
            <div className="w-full flex flex-col lg:flex-row justify-center items-center">
                <Animated animationIn="fadeIn" animationInDelay={1000}>
                    <H2 className="mr-0 sm:mr-2 mb-2 text-blue">therapeutic.</H2>
                </Animated>
                <Animated animationIn="fadeIn" animationInDelay={2000}>
                    <H2 className="mr-0 sm:mr-2 mb-2 text-blue">focused.</H2>
                </Animated>
                <Animated animationIn="fadeIn" animationInDelay={3000}>
                    <H2 className="mr-0 sm:mr-2 mb-2 text-blue">specific.</H2>
                </Animated>
            </div>
        </BackgroundImage>
    )
}

export default Hero;
