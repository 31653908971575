import React from 'react';
import Layout from '../components/layout';
// import Notes from '../components/notes';
import SEO from '../components/seo';
import Hero from '../components/hero';
import Landing from '../components/landing';
import Pricing from "../components/pricing";
import Testimonials from "../components/testimonials";
import Contact from "../components/contact";
import Footer from "../components/footer";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[]}
        title="Annie B Massage"
      />

      <Hero/>
      <Landing/>
      <Pricing/>
      <Testimonials/>
      {/*<Notes/>*/}
      <Contact/>
      <Footer/>
    </Layout>
  );
}

export default IndexPage;
