import React, {useState} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import PhoneLogo from '../images/phone-solid.svg';
import EmailLogo from '../images/envelope-solid.svg';
import MapLogo from '../images/map-marker-alt-solid.svg';
import Modal from './modal';
import {H4} from './type';


export default function Contact() {
    const q = useStaticQuery(graphql`
        query { 
            hero: file(relativePath: {eq: "annie-dog.jpg"}) {
                image: childImageSharp {
                    fluid(maxWidth:2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
         }
    `)
    const [showLocation,setShowLocation] = useState(false);
    return (
        <BackgroundImage fluid={q.hero.image.fluid}
                         className="flex flex-col justify-evenly items-center text-cream relative">
            <div className="absolute bg-black opacity-50 w-full h-full -z-10"/>
            <div className="py-12 flex flex-col justify-between items-center min-h-screen-13/20">
                <div className="text-center">
                    <p className="text-shadow-hero font-black leading-none whitespace-nowrap text-5xl md:text-8xl lg:text-9xl">get in touch</p>
                </div>
                <div className="flex flex-wrap md:flex-nowrap justify-evenly items-start ">
                    <div className="w-1/2 md:w-64 text-center">
                        <div className="bg-blue rounded-full w-12 h-12 md:w-24 md:h-24 hover:bg-brown flex justify-center items-center mx-auto mb-1 md:mb-2">
                            <a href="tel:403-678-0475" className="inline-block">
                                <PhoneLogo className="w-6 md:w-12 h-6 md:h-12"/>
                            </a>
                        </div>
                        <a href="tel:403-678-0475" className="text-cream hover:text-blue text-xs md:text-base">
                            403.678.0475
                        </a>
                    </div>
                    <div className="w-1/2 md:w-64 text-center">
                        <div className="bg-blue rounded-full w-12 h-12 md:w-24 md:h-24 hover:bg-brown flex justify-center items-center mx-auto mb-1 md:mb-2">
                            <a href="mailto:info@anniebmassage.ca" className="inline-block">
                                <EmailLogo className="w-6 md:w-12 h-6 md:h-12"/>
                            </a>
                        </div>
                        <a href="mailto:info@anniebmassage.ca" className="text-cream hover:text-blue text-xs md:text-base">
                            info@anniebmassage.ca
                        </a>
                    </div>
                    <div className="w-full md:w-64 text-center mt-2 md:mt-0">
                        <div className="bg-blue rounded-full w-12 h-12 md:w-24 md:h-24 hover:bg-brown flex justify-center items-center mx-auto mb-1 md:mb-2">
                            <button onClick={() => setShowLocation(true)}>
                                <MapLogo className="w-6 md:w-12 h-6 md:h-12"/>
                            </button>
                        </div>
                        <div>Millarville, AB</div>
                    </div>
                </div>
            </div>
            <Modal title="Bookings" show={showLocation} onClose={() => setShowLocation(false)} size="w-120 h-120">
                <div className="text-center">
                    {/*<H3 className="text-brown mb-2">bookings</H3>*/}
                    <H4 className="mt-2 text-brown max-w-sm mx-auto text-center">
                        Located 5 minutes W of Millarville on Highway 549 W.
                    </H4>
                    <H4 className="text-brown max-w-sm mx-auto mt-8">
                        Exact location will be given at time of booking.
                    </H4>
                </div>
            </Modal>
        </BackgroundImage>
    )
}
