import React, {useState} from 'react';
import {Section} from './page';
import Logo from "../images/logo.svg";
import {Body2, H3} from "./type";
import Button from "./button";
import BookModal from "./book";

export default function Landing() {
    const [book, setBook] = useState(false);
    return (
        <Section padBottom={true}>
            <div className="flex flex-wrap items-center">
                <div className="w-full md:w-1/3">
                    <Logo className="w-48 mx-auto md:w-64"/>
                </div>
                <div className="w-full md:w-2/3 py-4 px-0 md:px-4">
                    <div data-sal="fade" data-sal-delay={100} data-sal-easing="ease-out" data-sal-duration={1000}>
                        <Body2 className="mb-4">
                            Annie is a Registered Massage Therapist with the Canadian Massage and
                            Manual Osteopathic Therapists Association (CMMOTA). She graduated in
                            2014, with honors, from a 2200-hour program at MH Vicars School of
                            Massage Therapy. She practiced in a clinical setting in Canmore, Alberta
                            for over 7 years, until relocating briefly to the Comox Valley in
                            British Columbia. Though Vancouver Island is a beautiful place to visit,
                            she soon realized that Alberta is where her heart truly is. In the
                            Spring of 2022, she figured it was time to move back to the province she
                            had lived in for 21 years, but decided to trade the Rockies for the
                            beautiful Foothills. She couldn’t be happier with her choice. What a
                            stunning place! Her partner, her pooch and her feel like they have
                            finally landed home. She is looking forward to getting to know more
                            members of the community and building long-lasting relationships.
                        </Body2>
                    </div>
                    <div data-sal="fade" data-sal-delay={200} data-sal-easing="ease-out" data-sal-duration={1000}>
                        <Body2 className="mb-4">
                            Annie’s passion lies in therapeutic massage, but she can also provide a
                            very good relaxation massage if that’s what you’re looking for. She
                            tailors her treatment to your specific needs and goals, using a
                            combination of techniques including deep tissue, myofascial release,
                            trigger point therapy, cupping, relaxation, and TMJ. Her style of
                            massage is therapeutic, focused and specific. She can provide a deep,
                            firm pressure.
                        </Body2>
                    </div>
                    <div data-sal="fade" data-sal-delay={300} data-sal-easing="ease-out" data-sal-duration={1000}>
                        <Body2 className="mb-4">
                            Annie’s goal is to make sure you feel better when you walk out of the
                            treatment room than when you originally walked in. Everyone deserves a
                            bit of TLC. Her intention is to give you just that, and also make sure
                            that you feel like your muscles have been worked on. She wants to get
                            you back to optimum health, so you can enjoy whatever activities make
                            you happy and smile. She truly cares about helping you speed up your
                            recovery, decrease your stress, pain and tension, and improve your
                            mobility, so you can work and play better.
                        </Body2>
                    </div>
                    <div data-sal="fade" data-sal-delay={400} data-sal-easing="ease-out" data-sal-duration={1000}>
                        <Body2 className="mb-4">
                            Your comfort and well-being are Annie’s priorities. She offers a clean
                            and quiet space in her home, uses organic fractionated coconut oil, and
                            provides a comfortable table, equipped with a table warmer and
                            additional heat pad if required, to keep you cozy and warm. Her schedule
                            is flexible. She is available for bookings 7 days a week, including
                            evenings, weekends and holidays. Do not hesitate to ask for a specific
                            day/time. She will always do her best to accommodate any request.
                        </Body2>
                    </div>
                    <div data-sal="fade" data-sal-delay={500} data-sal-easing="ease-out" data-sal-duration={1000}>
                        <Body2 className="mb-4">
                            To find balance and keep sanity in this ever-changing world, Annie loves
                            going for a mountain bike ride or a trail run, gardening, and hanging
                            out with her furry friend, who loves taking her out for a walk in
                            nature, and cuddling with her at any hour of the day. One of Annie’s
                            favorite things is to have a good laugh, the kind that really hurts your
                            abs and your cheeks! She is fluent in both French and English.
                        </Body2>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/3">
                </div>
                <div className="w-full md:w-2/3 p-0 md:p-4 text-center md:text-left">
                    <H3 className="text-brown mb-6">recovery. mobility. happy.</H3>
                    {/*<Button className="p-2 w-24 h-24" href="https://anniebmassage.janeapp.com/">*/}
                    {/*    book now*/}
                    {/*</Button>*/}
                    <Button className="p-2 w-32 h-32 text-lg" onClick={() => setBook(true)}>
                        book now
                    </Button>
                    <BookModal show={book} onClose={() => setBook(false)}/>
                </div>
            </div>
        </Section>
    )
}
