import React from 'react';
import PropTypes from 'prop-types';

export const Container = ({children, className}) => {
    return (
        <div className={`max-w-7xl mx-auto ${className}`}>
            {children}
        </div>
    )
}
Container.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
}
export const Section = ({children, padBottom, className}) => {
    return (
        <Container className="w-full">
            <section className={`${padBottom === true ? 'py-8 lg:py-16 ':'pt-8 lg:pt-16'} px-4 ${className}`}>
                {children}
            </section>
        </Container>
    )
};
Section.propTypes = {
    padBottom: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};
