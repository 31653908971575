import React from 'react';
import PropTypes from 'prop-types';

export default function Button({children, className, ...props}) {
    return (
        <a className={`bg-brown hover:bg-blue text-cream hover:text-brown font-black hover:text-white rounded-full whitespace-nowrap inline-flex justify-center items-center cursor-pointer ${className}`} {...props}>
            {children}
        </a>
    )
}

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
}
