import React from "react";
import PropTypes from "prop-types";

function Layout({ children }) {
  return (
      <div className="font-sans bg-cream">
          {children}
        </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
