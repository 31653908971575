/* eslint-disable no-unused-vars */
import React from 'react';
import Slider from "react-slick";
import {graphql, useStaticQuery} from "gatsby";
import {Body1, Body2, Caption, H3} from './type';
import {Section} from "./page";
import './slider.css';


export default function Testimonials() {
    const {testimonials} = useStaticQuery(graphql`
        query {
          testimonials: allTestimonialsJson {
            nodes {
              name
              location
              text
            }
          }
         }
    `)
    return (
        <>
            <Section>
                <H3 className="mb-2 text-brown">testimonials</H3>
                <Body1 className="mb-4">What clients say about Annie.</Body1>
            </Section>
            <div className="my-8 md:my-16">
                    <div className="max-w-6xl mx-auto">
                        <Slider autoplay={true} autoplaySpeed={12000} dots={true}>
                            {testimonials.nodes.map((t,i) =>
                                <div key={i}>
                                    <div className="flex flex-wrap md:flex-nowrap justify-center items-center">
                                        <div className="p-4">
                                            <div className="rounded-full border-2 border-solid border-blue bg-blue text-brown w-32 h-32 flex flex-col items-center justify-center">
                                                <Caption className="text-center font-bold mb-1">{t.name}</Caption>
                                                <p className="text-center text-xs font-light text-white">{t.location}</p>
                                            </div>
                                        </div>
                                        <div className="p-4">
                                            {t.text.map((p,j) => <Body2 key={j} className="mb-2 italic font-light">{p}</Body2>)}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
            </div>
        </>

    )
}
