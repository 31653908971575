import React, {useState} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {Section} from './page';
import {Body1, Body2, Caption, H2, H3, H4} from './type';
import Button from './button';
import BookModal from "./book";



export default function Pricing() {
    const {prices} = useStaticQuery(graphql`
        query { 
            ccfs: file(relativePath: {eq: "ccfs.png"}) {
                image: childImageSharp {
                    fluid(maxWidth:512) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            prices: allPricesJson {
                nodes {
                    title
                    cost
                    time
                    image {
                      sharp: childImageSharp {
                        fluid(maxWidth: 364) {
                          src: srcWebp
                        }
                      }
                    }
                  }  
                }
         }
    `)
    const [book, setBook] = useState(false);
    return (
        <Section padBottom={true}>
            <H3 className="mb-2 text-brown">pricing</H3>
            <Body1 className="mb-2">All prices include GST.</Body1>
            <Caption>Receipts reimbursable by insurance companies. Direct billing available.</Caption>
            <Caption className="mb-4">Accepting cash, e-transfer or cheque.</Caption>
            <div className="flex flex-col-reverse md:flex-row items-center">
                <div className="w-full md:w-1/4 text-center">
                    <Button className="text-2xl p-4 w-40 h-40" onClick={() => setBook(true)}>
                        book now
                    </Button>
                    <BookModal show={book} onClose={() => setBook(false)}/>
                </div>
                <div className="w-full md:w-3/4">
                    <div className="flex flex-wrap items-center justify-center mb-4">
                        {prices.nodes.map((p,i) =>
                            <div key={p.title} className="w-64 h-64 p-4" data-sal="zoom-in" data-sal-delay={i*150}>
                                <div className="w-full h-full border-8 border-blue border-solid rounded-full bg-cover bg-center text-cream flex flex-col items-center justify-center relative"
                                     style={{backgroundImage: `url(${p.image.sharp.fluid.src})`}}>
                                    <div className="absolute w-full h-full bg-black opacity-50 rounded-full"/>
                                    <H2 className="z-50 text-shadow-hero">{p.time}</H2>
                                    <Body2 className="text-center z-50 text-shadow-hero mb-2">minute massage</Body2>
                                    <H4 className="z-50 text-shadow-hero">{p.cost}</H4>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>

            {/*<div className="flex flex-wrap md:flex-nowrap items-center justify-center">*/}
            {/*    <div className="w-64 p-12">*/}
            {/*        <Img fluid={ccfs.image.fluid} />*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <H4 className="text-brown mb-2">$1 from every treatment is donated</H4>*/}
            {/*        <Body2>*/}
            {/*            A dollar from every treatment is donated to the Cumberland Community Forest Society to help conserve and protect the*/}
            {/*            Cumberland Forest.*/}
            {/*        </Body2>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </Section>
    )
}
